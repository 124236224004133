import React, { useState } from 'react';

function AccountLotsPage() {
  const [mt4AccountNumber, setMt4AccountNumber] = useState('');
  const [mt5AccountNumber, setMt5AccountNumber] = useState('');

  const handleGetLots = () => {
    // Logic to handle "Get Lots" action for both MT4 and MT5
    console.log('MT4 Account Number:', mt4AccountNumber);
    console.log('MT5 Account Number:', mt5AccountNumber);
  };

  return (
    <div>
      {/* Top section div */}
      <div style={{ height: '30px' }} className="container-fluid">
        <div className="page-title" style={{ display: 'none' }}>
          <div className="row">
            <div className="col-6 col">
              <h3></h3>
            </div>
            <div className="col-6 col">
              <ol className="breadcrumb">
                <li className="breadcrumb-item"><a href="/dashboard">Dashboard</a></li>
                <li className="breadcrumb-item active">Clients</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      {/* Cards for MT4 and MT5 */}
      <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
        {/* MT4 Card */}
        <div style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: '300px'
        }}>
          <h4 style={{ textAlign: 'center' }}>MT4</h4>
          <input
            type="text"
            value={mt4AccountNumber}
            onChange={(e) => setMt4AccountNumber(e.target.value)}
            placeholder="Enter MT4 account number"
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '15px' }}
          />
        </div>

        {/* MT5 Card */}
        <div style={{
          border: '1px solid #ccc',
          borderRadius: '8px',
          padding: '20px',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
          width: '300px'
        }}>
          <h4 style={{ textAlign: 'center' }}>MT5</h4>
          <input
            type="text"
            value={mt5AccountNumber}
            onChange={(e) => setMt5AccountNumber(e.target.value)}
            placeholder="Enter MT5 account number"
            style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc', marginBottom: '15px' }}
          />
        </div>
      </div>

      {/* Shared Get Lots Button */}
      <div style={{ textAlign: 'center', marginTop: '20px' }}>
        <button
          type="button"
          onClick={handleGetLots}
          style={{
            backgroundColor: '#007bff',
            color: '#fff',
            padding: '10px 20px',
            border: 'none',
            borderRadius: '4px',
            cursor: 'pointer'
          }}
        >
          Get Lots
        </button>
      </div>
    </div>
  );
}

export default AccountLotsPage;
